import Head from "next/head";
import Header from "components/shared/Header";
import { useCurrentUserContext } from "contexts/CurrentUserContext";


export default function App({children}) {
  const { currentUser, loading } = useCurrentUserContext();

  if(loading) {
    return (
      <>
        <Head>
          <title>Line of Thought - A minimalist mood and life tracker.</title>
          <meta
            name="viewport"
            content="initial-scale=1.0, width=device-width viewport-fit=cover"
          />
          <link rel="icon" href="/favicon.ico" />
          <link
            rel="apple-touch-icon"
            href="https://lineofthought.com/lineofthought.png"
          />
        </Head>

        <div className="grid h-screen place-items-center">
          <div className="text-center">
            <p className="text-2xl font-bold mb-1">Line of Thought</p>
            <p className="">Just a sec, we gotta load stuff.</p>
            <p className="mt-8">
              <i className="fas fa-arrows-rotate text-2xl animate-spin" />
            </p>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className="bg-white text-black">
      <Head>
        <title>Line of Thought - A minimalist mood and life tracker.</title>
        <meta
          name="viewport"
          content="initial-scale=1.0, width=device-width viewport-fit=cover"
        />
        <link rel="icon" href="/favicon.ico" />
        <link
          rel="apple-touch-icon"
          href="https://lineofthought.com/lineofthought.png"
        />
      </Head>
      <Header user={currentUser} />
      <div className="my-4">
        {children}
      </div>
    </div>
  );
}

