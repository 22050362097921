import { useLazyQuery } from "@apollo/client";
import { useEffect, useMemo, useState } from "react";
import UserType from "types/UserType";
import { findUserByUserId } from "queries/users/getUserByUserId";
import { useCurrentSession } from "./useCurrentSession";
import moment from "moment";
import findIndex from "lodash/findIndex";
import { generateDemoJournals } from "lib/journalHelper";

function useCurrentUser() {
  const { session, loading: sessionLoading } = useCurrentSession();
  const [user, setUser] = useState<UserType>({demo: true, journals: [], settings: {}});
  const [loadingUser, setLoadingUser] = useState(false);
  const [getUser] = useLazyQuery(findUserByUserId, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data.users_by_pk) {
        const journals = data.users_by_pk.journals.map((journal) => {
          return {
            ...journal,
            ...{ mDate: journal.date ? moment(journal.date) : null }
          }
        });
        setUser({
          ...data.users_by_pk,
          ...{ demo: false },
          ...{ journals }
        });
      }
      setLoadingUser(false);
    }
  });

  const loadUser = () => {
    getUser({
      variables: {
        userId: session.id,
      },
    });
  };

  useEffect(() => {
    if (session?.id && !user?.id) {
      setLoadingUser(true);
      loadUser();
    }
  }, [session]);

  const setJournal = (journal) => {
    const index = findIndex(journal, (j) => j.date === journal.date);
    let journals = [...user.journals];

    if(index === -1) {
      journals.push(journal);
    } else {
      journals.splice(index, 1, journal);
    }

    setUser({
      ...user,
      ...{demo: false},
      ...{ journals }
    });
  }

  const demoUser = useMemo(() => {
    return {
      demo: true,
      journals: generateDemoJournals(500),
      settings: {}
    }
  }, [])

  return {
    loading: sessionLoading || loadingUser,
    currentUser: user,
    setJournal,
    demoUser
  };
}

export default useCurrentUser;
