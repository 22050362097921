import Link from "next/link";
import AvatarDropdown from "components/shared/nav/AvatarDropdown";

export default function Header({user}) {  
  if(user.demo) {
    if(window.location.pathname !== "/") {
      return (
        <nav className="mt-2 ml-4">
          <Link href="/" passHref>
            <a className="group text-xl font-extrabold text-gray-900 tracking-tight space-x-2 flex items-center">
              <i className="fa-solid fa-head-side-brain"></i>
              <span className="group-hover:underline">
                Line of Thought
              </span>
              <div className="hidden group-hover:block">
                <i className="fa-solid fa-arrow-right text-base"></i>
              </div>
            </a>
          </Link>
        </nav>
      )
    } else {
      return <></>;
    }
  } else {
    return (
      <nav className="absolute top-0 right-0 mt-4 mr-4 flex items-center space-x-4 hidden md:block">
        <AvatarDropdown user={user} />
      </nav>
    );
  }
}