import { createContext, useContext } from "react";
import useCurrentUser from "hooks/useCurrentUser";

const CurrentUserContext = createContext(null);

const CurrentUserWrapper = ({ children }) => {
  const currentUserState = useCurrentUser();

  return (
    <CurrentUserContext.Provider value={currentUserState}>
      {children}
    </CurrentUserContext.Provider>
  );
};

const useCurrentUserContext = () => {
  return useContext(CurrentUserContext);
};

export { CurrentUserContext, CurrentUserWrapper, useCurrentUserContext };
