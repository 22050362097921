import { useEffect, useState } from "react";
import Router from "next/router";
import { fetchSession } from "lib/tokenLoader";
import SessionType from "types/SessionType";

export function useCurrentSession({
  redirectTo = "",
  redirectIfFound = false,
} = {}) {
  const [session, setSession] = useState<SessionType | null>();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchSession().then((newSession) => {
      setSession(newSession);
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    if (!redirectTo || isLoading) return;
    if (
      // If redirectTo is set, redirect if the user was not found.
      (redirectTo && !redirectIfFound && !session) ||
      // If redirectIfFound is also set, redirect if the user was found
      (redirectIfFound && session)
    ) {
      Router.push(redirectTo);
    }
  }, [redirectTo, redirectIfFound, session, isLoading]);

  return {
    session,
    loading: isLoading,
  };
}
