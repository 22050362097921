import moment from "moment";
import JournalType from "types/JournalType";

export function generateJournalsForMonthWithPlaceholders(journals, month) {
  const nextMonthDate = moment(month).add(1, 'month').startOf('month'),
        today = moment(new Date()),
        startOfMonth = moment(month).startOf('month');

  var journalsForMonth = journals.filter((journal) => {
    return journal.mDate >= startOfMonth && journal.mDate < nextMonthDate;
  }).sort((a, b) => (a.mDate > b.mDate) ? 1 : -1);

  // Add in empty reviews for days without one
  for(var i=1; i<=month.daysInMonth(); i++) {
    if(!journalsForMonth[i-1] || (journalsForMonth[i-1] && journalsForMonth[i-1].mDate.date() !== i)) {
      const missingDate = moment(new Date(month.year(), month.month(), i));
      if(missingDate <= today) {
        journalsForMonth.splice(i-1, 0, {
          date: missingDate.toDate(),
          mDate: missingDate
        });
      }
    }
  }
  return journalsForMonth;
}


export function layoutIdForJournal(journal) {
  return journal.rating > 0 ? journal.date : null;
}

// returns a gaussian random function with the given mean and stdev.
function gaussian(mean, stdev) {
  var y2;
  var use_last = false;
  return function() {
    var y1;
    if (use_last) {
      y1 = y2;
      use_last = false;
    } else {
      var x1, x2, w;
      do {
        x1 = 2.0 * Math.random() - 1.0;
        x2 = 2.0 * Math.random() - 1.0;
        w = x1 * x1 + x2 * x2;
      } while (w >= 1.0);
      w = Math.sqrt((-2.0 * Math.log(w)) / w);
      y1 = x1 * w;
      y2 = x2 * w;
      use_last = true;
    }

    var retval = mean + stdev * y1;
    if (retval > 0) {
      const f = Math.floor(retval);
      if(f > 9) {
        return 9;
      } else {
        return f;
      }
      
    }
    return -retval;
  }
}

// make a standard gaussian variable.     
var randomRating = gaussian(7, 2);


export function generateDemoJournals(count) {
  const journals:JournalType[] = [], now = moment(new Date);
  journals.unshift({
    rating: 9,
    date: now.format("YYYY-MM-DD"),
    mDate: now,
    notes: `Fun night hanging out with friends at T.F. Brewing`
  } as JournalType);
  for(var i=1; i<count; i++) {
    const nextMonthDate = moment(now).subtract(i, 'day');
    journals.unshift({
      rating: randomRating(),
      date: nextMonthDate.format("YYYY-MM-DD"),
      mDate: nextMonthDate,
      notes: `This is a sample note for ${nextMonthDate.format("YYYY-MM-DD")}`
    } as JournalType)
  }
  return journals;
}

export const tagUid = (tag) => {
  return [tag.uid, tag.tag, tag.icon, tag.color].join("=");
}
