import { Fragment } from "react"
import { Menu, Transition } from "@headlessui/react";
import { signOut } from "next-auth/react";
import Link from "next/link";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function AvatarDropdown({user}) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <Menu.Button className="inline-flex justify-center w-full md:px-4 md:py-2 text-sm font-medium">
        <div className="overflow-hidden h-8 w-8 md:h-12 md:w-12 rounded-full">
          <img referrerPolicy="no-referrer" src={user.image} />
        </div>
      </Menu.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-50 origin-top-right right-0 md:left-auto absolute mt-2 w-56 rounded-md shadow-lg bg-gray-100 text-black dark:text-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none text-gray-800">
          <div className="px-4 py-3">
            <p className="text-xs text-gray-500">Signed in as</p>
            <p className="text-sm font-medium text-gray-700 truncate">{user.email}</p>
          </div>
          <div className="py-1 text-gray-800">
            <Menu.Item>
              {({ active }) => (
                <Link href="/setup" passHref>
                  <a
                    href="#"
                    className={classNames(
                      active ? 'font-semibold' : '',
                      'block px-4 py-2 text-sm hover:bg-blue-200 hover:text-blue-800 hover:font-semibold'
                    )}
                  >
                    Setup Tags
                  </a>
                </Link>
              )}
            </Menu.Item>
          </div>
          <div className="py-1 text-gray-800">
            <Menu.Item>
              {({ active }) => (
                <Link href="/day" passHref>
                  <a className={classNames(
                      active ? 'font-semibold' : '',
                      'block px-4 py-2 text-sm hover:bg-blue-200 hover:text-blue-800 hover:font-semibold'
                    )}>
                    Day
                  </a>
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link href="/month" passHref>
                  <a className={classNames(
                      active ? 'font-semibold' : '',
                      'block px-4 py-2 text-sm hover:bg-blue-200 hover:text-blue-800 hover:font-semibold'
                    )}>
                    Month
                  </a>
                </Link>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <Link href="/year" passHref>
                  <a className={classNames(
                      active ? 'font-semibold' : '',
                      'block px-4 py-2 text-sm hover:bg-blue-200 hover:text-blue-800 hover:font-semibold'
                    )}>
                    Year
                  </a>
                </Link>
              )}
            </Menu.Item>
          </div>
          <div className="py-1 text-gray-800">
            <form method="POST" action="#">
              <Menu.Item>
                {({ active }) => (
                  <button
                    type="submit"
                    onClick={(e) => { e.preventDefault(); signOut({callbackUrl: `${window.location.origin}`}) }}
                    className={classNames(
                      active ? 'font-semibold' : '',
                      'block w-full text-left px-4 py-2 text-sm hover:bg-blue-200 hover:text-blue-800 hover:font-semibold'
                    )}
                  >
                    Sign out
                  </button>
                )}
              </Menu.Item>
            </form>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}