import { gql } from "@apollo/client";

export const findUserByUserId = gql`
  query FindUserByUserId($userId: uuid!) {
    users_by_pk(id: $userId) {
      id
      name
      email
      image
      settings

      journals {
        id
        rating
        notes
        tags
        date
      }
    }
  }
`;
