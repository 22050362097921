/* eslint-disable no-underscore-dangle */
import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  NormalizedCacheObject,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { fetchSession } from "lib/tokenLoader";

// Saved apolloClient for the duration of this request
let apolloClient;

const createApolloClient = () => {
  const ssrMode = typeof window === "undefined";

  const httpLink = createHttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
  });

  const ssrConnection = createHttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_URL,
  });

  const authLink = setContext((_, { headers }) => {
    return fetchSession().then((session) => {
      return {
        headers: {
          ...headers,
          ...{ authorization: `Bearer ${session.token}` },
        },
      };
    });
  });

  const ssrLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        ...{ "x-hasura-admin-secret": process.env.HASURA_ADMIN_SECRET },
      },
    };
  });

  const link = ssrMode
    ? ssrLink.concat(ssrConnection)
    : authLink.concat(httpLink);

  return new ApolloClient({
    ssrMode,
    link,
    cache: new InMemoryCache(),
  });
};

// from https://github.com/vercel/next.js/blob/canary/examples/api-routes-apollo-server-and-client/apollo/client.js
function initializeApollo(initialState = null) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    _apolloClient.cache.restore(initialState);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === "undefined") return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

function useApollo(initialState = null): ApolloClient<NormalizedCacheObject> {
  return initializeApollo(initialState);
}

export { initializeApollo, useApollo };
