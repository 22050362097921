import "styles/globals.css";
import "@fortawesome/fontawesome-pro/css/fontawesome.css";
import "@fortawesome/fontawesome-pro/css/solid.css";
import "@fortawesome/fontawesome-pro/css/regular.css";


import { useApollo } from "lib/apollo";
import { ApolloProvider } from "@apollo/client";
import { CurrentUserWrapper } from "contexts/CurrentUserContext";
import App from "components/shared/App";

function LineOfThoughtApp({ 
  Component, 
  pageProps: { ...pageProps }
}) {
  const apolloClient = useApollo(pageProps.initialApolloState);

  return (
    <ApolloProvider client={apolloClient}>
      <CurrentUserWrapper>
        <App>
          <Component {...pageProps} />
        </App>
      </CurrentUserWrapper>
    </ApolloProvider>
  );
}

export default LineOfThoughtApp